.integrationBuilder {
  height: 100%;
}
.urlBuilder {
  display: flex;
  height: 40px;
}
input {
  height: 100%;
  border-radius: 5px;
  color: #444;
}
.httpRequestType {
  height: 100%;
  flex: 1;
  background: #eee;
  border: none;
  border-right: 1px solid #ddd;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 1rem;
  outline: none;
  align-items: center;
  display: flex;
  color: #444;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
}
.authType {
  height: 40px;
}
.authRow {
  display: flex;
  border: 1px solid #ddd;
}
.parentWebhookRow {
  display: flex;
  border: 1px solid #ddd;
  flex: 1;
}
.httpRequestType .httpRequestTypeOptions {
  position: absolute;
  background: #eee;
  width: 100%;
  left: 0;
  top: 40px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10;
}
.authRow .httpRequestType,
.parentWebhookRow .httpRequestType {
  background: none;
  flex: 1;
}
.httpRequestTypeOut {
  border-bottom-left-radius: 0;
}
.httpRequestTypeIcon {
  margin-left: auto;
}
.httpRequestTypeOut .httpRequestTypeIcon {
  transform: rotate(180deg);
}
.httpRequestTypeOptionsRow {
  padding: 0.5rem 1rem;
  min-height: 28px;
}
.httpRequestTypeOptionsRow:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.httpRequestTypeOptionsRow:hover {
  background: #ddd;
}
.urlBuilderInput {
  flex: 4;
  margin-right: 1rem;
  padding: 0 1rem;
}
.urlInput {
  flex: 6;
  margin: 0;
  margin-right: 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btnStyle {
  flex: 1.5;
  outline: none;
  border: none;
  border-radius: 5px !important;
  background-color: #28a745;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.btnStyle:disabled {
  cursor: not-allowed !important;
}
.btnStyle:hover {
  background-color: #36954c;
}

.deleteButton {
  margin-right: 1rem;
  background-color: #dc3545;
  text-transform: uppercase;
}
.deleteButton:hover {
  background-color: #bc3240;
}

.sendButton {
  margin-left: 1rem;
  background-color: var(--buttonBlue);
  text-transform: uppercase;
}
.sendButton:hover {
  background-color: var(--buttonHoverBlue);
}
.tabContainer {
  height: 40%;
  padding: 0 0 1rem 0;
  position: relative;
}
.tabContainerLarge {
  height: 80%;
  overflow-y: scroll;
  padding-right: 1rem;
}
.httpResponseBody {
  height: 40%;
  border-top: 1px solid #eee;
}

.dictGrid {
  display: flex;
  border: 1px solid #eee;
  flex-direction: column;
}

.dictRow {
  display: flex;
  width: 100%;
}
.dictRow:first-child {
  border-bottom: 1px solid #eee;
}
.dictRowKey {
  flex: 0.5;
  border-right: 1px solid #eee;
  padding: 0.5rem;
}
.dictRowValue {
  flex: 1.5;
  border-right: 1px solid #eee;
  padding: 0.5rem;
}
.dictRowDelete {
  flex: 0.2;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
.dictInput {
  background: #fff;
  width: 100%;
}
.dictHeaderRow .dictInput {
  font-weight: 800;
}
.dictRowDelete .dictInput {
  justify-content: center;
  display: flex;
  align-items: center;
}
.deleteDictRowIcon {
  cursor: pointer;
  font-size: 1.5rem;
}
.tabHeaderRow {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.authForm {
  flex: 6;
  display: flex;
}
.authInputField {
  flex: 1;
  background: #fff;
  border-right: 1px solid #ddd;
  padding: 0 1rem;
}
.formRow {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.formFieldRow {
  height: 40px;
  border: 1px solid #ddd;
  display: flex;
}
.formFieldCol {
  height: 100%;
}
.formFieldInput {
}
.notificationIcon {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #1976d2;
  animation: glow 0.5s infinite alternate;
}
.hideNotificationIcon {
  display: none;
}
@keyframes glow {
  from {
    background: #1976d2;
  }
  to {
    background: #fff;
  }
}

.saveGlowing {
  animation: saveGlowing 0.5s infinite alternate;
}
@keyframes saveGlowing {
  from {
    background-color: #28a745;
  }
  to {
    background-color: #4edd6f;
  }
}

.mappings {
  width: 90%;
  display: flex;
  flex-direction: column;
  /* gap:1rem; */
}
.mappings .authRow {
  flex-wrap: wrap;
  flex: 1;
}
.responseRowContainer {
  display: flex;
  flex: 1;
  margin-top: 2rem;
}
.addResponseRow {
  width: 50px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  /* background:var(--buttonBlue); */
  flex-direction: column;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.responseRowButton {
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.responseRowButton:first-child {
  border-bottom: 1px solid #ddd;
}
.addRowMappingIcon,
.deleteRowMappingIcon {
  color: var(--buttonBlue);
  font-size: 1.5rem;
  cursor: pointer;
}
.deleteRowMappingIcon:hover {
  color: var(--buttonHoverBlue);
}
.mappingsLanguageSelect {
  display: inline-flex;
  margin-left: auto;
  right: 1rem;
  position: absolute;
  top: 1rem;
}

.mappings .mappingResponse {
  flex: 2 1;
  border-right: 1px solid #ddd;
  padding-bottom: 0;
}
.mappings .deviation {
  flex: 1;
  height: 100px;
  border-bottom: 1px solid black;
  display: flex;
  gap: 2;
}
.mappings .deviationTitle {
  border-right: 1px solid black;
  padding: 1rem;
  width: 100px;
  font-weight: bold;
}
.mappings .deviationText {
  padding: 1rem;
  width: 100%;
}
.mappings .deviation:last-child {
  border-bottom: none;
}
.mappings .mappingResponse textarea {
  width: 100%;
  height: 100%;
  outline: none;
  resize: none;
  margin-top: 1px;
  border: none;
  padding: 0;
  line-height: 1.3;
}
.mappings .mappingResponseTitle {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ddd;
}
.mappings .condition {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #eee;
}
.mappings .condition .conditionText {
  display: flex;
}
.mappings .condition .conditionIcon {
  font-size: 1.2rem;
}

.conditionRow {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  flex: 1;
}
.conditionInput {
  flex: 2;
}
.conditionSelectContainer {
  flex: 1;
}
.conditionSelect {
  height: 30px;
}
.statementSummaryRow {
  display: flex;
  align-items: center;
}
.statementSummaryItem {
  margin: 0 0.2rem;
}
.conditionRowContainer {
  display: flex;
  flex-direction: column;
}
.addRowToConditionsIcon,
.deleteRowToConditionsIcon {
  font-size: 1.7rem;
  cursor: pointer;
  color: var(--buttonBlue);
}
.addRowToConditionsIcon:hover {
  color: #963083;
}
.deleteRowToConditionsIcon {
  color: var(--buttonBlue);
}
.deleteRowToConditionsIcon:hover {
  color: var(--buttonHoverBlue);
}
.addConditionRowIconContainer {
  width: 35px;
  align-items: center;
  display: flex;
  margin-bottom: 4px;
}
.dictGridContainer {
  display: flex;
}

.fallbackContainer {
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
}
.fallbackHeader {
  padding: 1rem;
  background: orange;
  color: black;
  margin-top: 2rem;
  margin-bottom: 0;
}
.fallbackRow {
  display: flex;
}
.fallbackRow:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.fallbackColumn {
  border-right: 1px solid #eee;
  padding: 1rem;
}
.fallbackColumnDelete {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.fallbackDeleteIcon {
  font-size: 1.5rem;
  cursor: pointer;
}
.apiReponseHeader {
  margin-top: 1rem;
}
.apiReponseResult {
  margin-left: 1rem;
}
.urlBuilderInput:disabled {
  cursor: not-allowed;
}
.btnDisabled,
.disableButton {
  cursor: not-allowed;
  background: #ddd;
}
.btnDisabled:hover,
.disableButton:hover {
  background: #ddd;
}
.operatorStyle {
  font-weight: 1000;
  font-size: 1.3rem;
}
.contRow {
  width: 100%;
  display: flex;
}
.triggerHookRow {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.webhookTriggerSelectContainer {
  flex: 1;
}
.webhookTriggerSelect {
  height: 30px;
  min-width: 200px;
}
.gptBodyArea {
  width: 100%;
  height: 80%;
  padding: 1rem !important;
}
.gptResponseLabelInput {
  padding: 1rem;
  margin-bottom: 0.5rem;
}
.flexRow {
  display: flex;
  gap: 1rem !important;
}
.flexSize1 {
  flex: 1;
}
.flexSize2 {
  flex: 2;
}
.flexSize3 {
  flex: 3;
}
.borderInput {
  border: 1px solid #ddd;
  background: none;
  padding: 0 1rem;
}
