.chatboxContainer{
    position:absolute;
    width:350px;
    height:650px;
    background:#ffffff;
    right:-350px;
    display:grid;
    z-index:1000;
    align-items:center;
    transition:right 0.5s ease-in-out;
    content:'sdfsdf';
    box-shadow: 0px 0px 8px 3px rgb(210 210 210);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    grid: 1fr minmax(15%, 85%) 1.5fr / 100%;
    grid-template-areas:    "chatboxHeader"
                            "chatboxContent"
                            "chatboxInput";
    grid-auto-flow: row;
}
.select{
    height:30px;
    color:#fff !important;
}
.chatboxContainer[data-expanded="true"] {
    right: 0;
}
.handle{
    display:flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    width:20px;
    height:100px;
    background:var(--buttonBlue);
    left:-20px;
    color:#ffffff;
    border-bottom-left-radius:10px;
    border-top-left-radius:10px;
    cursor:pointer;
}
.toggleText{
    width:100px;
    transform: rotate(-90deg);
    position: absolute;
    text-align: center;
}
.chatboxContainer .chatboxHeader{
    grid-area: chatboxHeader;
    background:var(--buttonBlue);
    height:100%;
    border-top-left-radius: 10px;
    display:flex;
    justify-content: right;
    align-items: center;
    color:#ffffff;
    padding:0 10px;
    gap:1rem;
}
.chatboxContainer .chatboxContent{
    grid-area: chatboxContent;
    height:100%;
    padding:10px 15px;
    display:flex;
    flex-direction: column;
    gap:1rem;
    overflow-y: scroll;
}
.chatboxContainer .chatboxContent .chatMessage{
    width:80%;
    height:auto;
    background:var(--buttonBlue);
    padding:5px 10px;
    border-radius:10px 0 10px 10px;
    position:relative;
    margin-left:auto;
    color:#ffffff;
}
.chatboxContainer .chatboxContent .chatMessage:before{
    content: '';
    position: absolute;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 6px solid var(--buttonBlue);
    right: -13px;
    width: 0;
    height: 0;
    top: 0px;
}
.chatboxContainer .chatboxContent .chatMessage[data-source="ai"] {
    margin-left: revert;
    border-radius:0 10px 10px 10px;
    background:#c3dfff;
    color:#222222;
}
.chatboxContainer .chatboxContent .chatMessage[data-source="ai"]:before {
    content: '';
    position: absolute;
    border-right: 5px solid #76b2f267;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    left: -13px;
    width: 0;
    height: 0;
    top: 0px;
}
.chatboxContainer .chatboxInput{
    grid-area: chatboxInput;
    height:100%;
    background:#f8f8fa;
    border-top:1px solid #bbbbc022;
    display:flex;
    gap:1rem;
    padding:1rem;
    align-items: center;
    border-bottom-left-radius: 10px;
}
.chatboxContainer .chatboxInput input{
    border-radius:20px;
    padding:1rem 20px;
    cursor:text;
}
.chatboxContainer .chatboxInput input:disabled{
    cursor:default;
}
.chatboxContainer .chatboxInput .inputIcon{
    font-size:2rem;
    color:#007bff;
    cursor:pointer;
}
.chatboxContainer .chatboxInput .disabledButton{
    cursor:default;
    color:#ccc;
}
.chatboxContainer .chatboxHeader .headerTitle{
    font-size:1.5rem;
    margin-right:auto;
}
.chatboxContainer .chatboxHeader .headerIcon{
    font-size:2rem;
    color:#ffffff;
    cursor:pointer;
}