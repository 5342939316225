.integrationConfigurator{
    height:100%;
    overflow-y:scroll;
    overflow-x:hidden;
}
.integrationGeneral{
    display: grid;
    grid: 150px auto auto / minmax(15%, 1fr) 10fr;
    gap: 1rem;
    grid-template-areas: "integrationImageContainer integrationSettingsContainer" "integrationRequiredFieldsContainer integrationRequiredFieldsContainer" "integrationNewContainer integrationNewContainer";
    transition: all 1s ease;
    height: 50%;
    border-bottom:2px solid #aaa;

}
.integrationImageContainer{
    grid-area: integrationImageContainer;
    height:100%;
    position: relative;
    display: flex;
}
.integrationSettingsContainer{
    grid-area: integrationSettingsContainer;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.integrationRequiredFieldsContainer{
    grid-area: integrationRequiredFieldsContainer;
    height:100%;
    width:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding:1rem 0;
}
.integrationNewContainer{
    grid-area: integrationNewContainer;
    height:100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding:1rem 0;
}



.integrationImage{
    border:2px solid #ddd;
    height:100%;
    width:100%;
    border-radius:5px;
}
input{
    height:40px;
}
.integrationInputBox{
    background: #fff;
    border: 1px solid #ddd;
    flex: 1;
    padding: 0 1rem;
}
textarea{
    padding:1rem !important;
    border-radius:5px;
    resize: none !important;
}
.integrationInputBox:focus{
    outline:none;
}
.btnDisabled, .disableButton{
    cursor: not-allowed;
    background:#ddd;
}
.btnDisabled:hover, .disableButton:hover{
    background:#ddd;
}
.btnStyle{
    background: var(--buttonBlue);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    width: 1fr;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:0 1.5rem;
    text-transform: uppercase;
}
.btnStyle:hover{
    background:var(--buttonHoverBlue);
}
.saveBtn{
    margin-left:auto;
}
.row{
    display:flex;
    gap:1rem;
    height:40px;
}
.integrationRequiredFieldsContainerHeader{
    align-items: center;
    display: flex;
}



.requiredField{
    
    background: #fff;
    border: 1px solid #ddd;
    flex: 1;
    padding: 0 1rem;

    height:40px;
    flex:1;
    
    border-radius:5px;
    padding:0 1rem;
    outline:none;
    align-items:center;
    display:flex;
    color: #444;
    position:relative;
    justify-content: space-between;
    cursor:pointer;
}
.requiredFieldIcon{
    margin-left:auto;
}
.requiredFieldOut .httpRequestIcon{
    transform: rotate(180deg);
}
.requiredField .requiredFieldOptions{
    position:absolute;
    background:#eee;
    width:100%;
    left:0;
    top:40px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index:10;
}
.requiredFieldOptionsRow{
    padding: 0.5rem 1rem;
    min-height:28px;
}
.requiredFieldOptionsRow:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.requiredFieldOptionsRow:hover{
    background:#ddd;
}
.dropDownDisabled{
    cursor: not-allowed;
}
.imageUpload{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor:pointer;
}
.imageUpload>input {
    display: none;
    cursor:pointer;
  }
.imageUpload img{
    width:100%;
    cursor:pointer;
}
.imageUpload label{
    cursor:pointer;
}
.testIntegrationRow{
    display:flex;
}
.columnSettings{
    border-right:1px solid #ddd;
    padding-right:2rem;
}
.columnSettings, .columnOutput{
    display:flex;
    flex:1;
    flex-direction: column;
}
.columnOutput{
    padding:0 1.5rem;
}
.httpRequestType .httpRequestTypeOptions{
    position:absolute;
    background:#eee;
    width:100%;
    left:0;
    top:28px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index:10;
}
.httpRequestType{
    background:none;
    flex:1;
    border: 1px solid black;
    flex: 1;
    margin-left: auto;
    padding: 0.5rem 1rem;
    cursor:pointer;
    position:relative;
}
.httpRequestTypeOut{
    border-bottom-left-radius: 0;
}
.httpRequestTypeIcon{
    margin-left:auto;
}
.httpRequestTypeOut .httpRequestTypeIcon{
    transform: rotate(180deg);
}
.httpRequestTypeOptionsRow{
    padding: 0.5rem 1rem;
    min-height:28px;
}
.httpRequestTypeOptionsRow:last-child{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.httpRequestTypeOptionsRow:hover{
    background:#ddd;
}
.warningMessage{
    font-size:1.5rem;
    color:#dc3545;
    display:block;
}