
.newAgentContent {
    color: black;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2rem;
    flex-direction: column;
}

.agentNewAgentExpanded {
    max-height: 100% !important;
    width: 100% !important;
}

.newAgentContent .newAgentHeader {
    padding: 0rem 0 2rem;

}

.newAgentContent .newAgentSection {
    padding: 2rem 0;
    border-top: 1.5px solid #eee;
    border-bottom: 1.5px solid #eee;

}

.sectionInfo {
    width: 100%;
}

.sectionTitle {
    font-weight: 600;
    color: #444;
}

.sectionInfo .sectionComment {
    color: #aaa;
    font-size: 1.3rem;
    margin-top: 1rem;

}

.newAgentNameInput, .newAgentSearchInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 1rem 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
    margin-bottom: 0 !important;
}

.languages {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
    margin-top: 1rem;
}

.languageLabel {
    background-color: #fbe2f7;
    border-radius: 2rem;
    font-size: 1.1rem;
    color: var(--buttonBlue);
    padding: 1rem 1.3rem;
    cursor: pointer;
}

.languageLabel:hover {
    background: var(--buttonBlue);
    color: #fff;
}

.newAgentContent .newAgentSection .selected {
    background: var(--buttonBlue);
    color: #fff;
}

.newAgentContent .newAgentFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 2rem 0 0;
    height: 100%;
}

.newAgentContent .summary {
    width: 100%
}

.warning {
    border-color: #f5c6cb;
    padding: 0.5rem;
    color: #721c24;
    background-color: #f8d7da;
    border-radius: 5px;
    margin-top: 1rem;
}

.newAgentContent .button {
    padding: 1rem 1.3rem;
    border-radius: 1rem;
    height: fit-content;
    justify-content: center;
    display: flex;
    font-weight: 500;
    margin-top: 1rem;
    cursor: pointer;
}

.newAgentContent .action {
    margin-top: auto;
}

.newAgentContent .confirm {
    background: var(--buttonBlue);
    color: #fff;
}

.newAgentContent .cancel {
    background: #eee;
    color: gray;
    margin-bottom: 1.5rem;
}

ul {
    list-style: none;
    padding-left: 0 !important;
}

li {
    color: gray;
}

.summaryItems {
    margin-top: 1rem;
}

.summaryItem {
    color: gray;
    display: flex;
    justify-content: space-between;
}

.total {
    color: #444;
    font-weight: 600;
}

.data {
    width: 100%;
}

h1 {
    color: #444;
}

h3 {
    color: gray;
}

