.chatMessageContainer{
    display:grid;
    height:100%;
    width:100%;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: "messagesList messageDetail";
    border-radius: 5px;;
    border: 1px solid #ddd;
}
.chatMessagesList{
    grid-area: messagesList;
    height:100%;
    overflow-y:scroll;
    padding:1rem;
}
.chatMessageDetail{
    grid-area: messageDetail;
    display:flex;
    flex-direction: column;
    align-items: space-between;
    gap:1rem;
    background-image: url('/public/qwd83nc4xxf41.jpg');
    padding:1rem;
}
.conversationListItemPhoto{
    width:50px;
    display: flex;
}
.conversationListItemPhotoHolder{
    width:45px;
    height:45px;
    display: flex;
    border-radius: 45px;
    color:#fff;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.conversationListItemContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
}
.conversationEmail{
    color:#000;
    font-weight: 600;
    font-size:14px;
}
.activeConversation .conversationEmail{
    color:#fff;
}
.activeConversation .conversationListItemSubheader{
    color:#fff;
}
.activeConversation .conversationLastMessageTime{
    color:#fff;
}
.conversationLastMessageTime{
    color:gray;
    font-weight: 400;
    font-size:11px;
}
.conversationListItem{
    display:flex;
    padding:1rem 0.5rem;
    gap:1rem;
    cursor:pointer;
    border-radius: 3px;
}
.conversationListItem:hover{
    background:#eee;
}
.conversationListItemHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}
.conversationListItemSubheader{

}
.activeConversation{
    background:#007bff;
}
.activeConversation:hover{
    background:#007bff;
}
.converstationMessage{
    background:#fff;
    padding:0.6rem;
    border-radius:3px;
    font-size:14px;
    max-width: 50%;
    position:relative;
}
.alignLeft{
    margin-right:auto;
}
.alignRight{
    margin-left:auto;
    background:#DCF8C6;
}
.messageAuthor{
    color:#007bff;
    font-weight: 600;
}
.messageBody{
    margin-right:30px;
    display: flex;
    align-items: center;
}
.messageTime{
    position:absolute;
    right:5px;
    bottom:5px;
    font-size:10px;
    color:#666;
}
.chatInput{
    display:flex;
    gap:1rem;
    margin-top:auto;
}
.chatSubmit{
    cursor: pointer;
    color: white;
    font-size: 1rem;
    background:#007bff;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.chatInputText{
    font-size:14px;
    padding:0.5rem;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .dotPulse {
    position: relative;
    left: -9999px;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    margin-top: 4px;
    margin-left: 15px;
  }
  .dotPulse::before, .dotPulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
  }
  .dotPulse::before {
    box-shadow: 9990px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dotPulse::after {
    box-shadow: 10010px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9990px 0 0 -5px;
    }
    30% {
      box-shadow: 9990px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9990px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9999px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10010px 0 0 -5px;
    }
    30% {
      box-shadow: 10010px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10010px 0 0 -5px;
    }
  }