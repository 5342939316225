.onlyDesktopContainer{
    width:100%;
    height: 100vh;
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction: column;
    padding: 2rem;
}
.onlyDesktopImage{
    font-size:17rem;
}
.onlyDesktopHeader{
    font-size:3rem;
}
.onlyDesktopText{
    font-size:2rem;
    text-align:center;
}
.onlyDesktopButton{
    background-color: var(--buttonBlue);
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    font-size: 2rem;
    padding: 1rem 2rem;
    margin-top: 1rem;
    text-decoration: none;
    text-transform: uppercase;
}
.onlyDesktopButton:visited, .onlyDesktopButton:focus, .onlyDesktopButton:active{
    color:#fff;
}
