
.scrollable{
    overflow-x:hidden;
    overflow-y: scroll;
    height: 100%;
}
.widgetHeader{
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    border-bottom: 1.5px solid #eee;
    padding-bottom:1rem;
    gap:1rem;
    position: relative;
}
.headerTitle{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
    margin-right:auto;
    left:0;
    position:absolute;
}
.buttonStyle{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.submitButton{
}
.buttonStyle:hover, .buttonStyle:focus {
    background-color: var(--buttonHoverBlue);
}
.formPanel{
    padding:1rem;
    padding-left:0;
}
.formRow{
    display:flex;
    gap:1rem;
    justify-content: space-between;
}
.noGap{
    gap:0 !important;
}
.formColumn{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.formHeaderText{
    padding: 0.5rem;
    border-bottom: 1.5px solid #eee;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.dropdownContainer{
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}
.dropdownContainerRow{
    display: flex;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}
.dropdownHeader{
    display: flex;
    justify-content: space-between;
}
.formElementContainer{
    flex: auto;
}
.wFull{
    width:100px;
    flex-grow:5;
    display:flex;
}
.phonenumberField{
    display: flex;
    align-items: center;
    background: #28a745;
    margin: 8px;
    border-radius: 4px;
    padding: 0 1rem;
    color: #fff;
}
.goLive{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    margin: 8px;
    width: 110px;
    align-items: center;
    text-transform: uppercase;
}
.goLiveDelete{
    cursor: pointer;
    background:rgb(211, 47, 47);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    margin: 8px;
    width: 110px;
    align-items: center;
    text-transform: uppercase;
}
.w100{
    width:100px;
    flex-grow:0;
}
.disabledButton{
    background:#eee;
    cursor:default;
}
.disabledButton:hover{
    background:#eee;
}
.buttons{
    display:flex;
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    gap:1rem;
}
.deleteButton{
    background:rgb(211, 47, 47);
    cursor:pointer;
}
.deleteButton:hover, .deleteButton:focus {
    background-color: rgb(180, 43, 43);
}
.secretsContainer{
    margin: 1rem 0;
    margin-left:1rem;
    border-top: 1px solid #eee;
    padding: 1rem;
    padding-left:0;
    padding-bottom:0;
    margin-bottom:0;
}
.secretsContainer .secretsContainerHeader .secretColumn{
    flex: 1 0 25%
}
.secretsContainerHeader .dropdownContainer{
}
.selectedSecretContainer{
    margin-top:1rem;
    display:flex;
    flex-wrap: wrap;
    gap:0.5rem;
    align-items: center;;
}
.hookSelection{
    margin-left: 1rem;
}
.hookSaveButton{
    flex: 0.4 !important;
    background-color: var(--buttonBlue);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
    height: 30px;
    border-radius:0.5rem;
    cursor:pointer;
    color:white;
}
.hookSaveButton:hover, .hookSaveButton:focus {
    background-color: var(--buttonHoverBlue);
}
.newSecretButton{
    height:100%;
}    
.humanHandoverSettings{
    margin-top:1rem;
    margin-bottom:10rem;
}
.newRow{
    width:100%;
}
.infoValueRow{
    width:100%;
}
.chatboxOptionLine{
    width: 100%;
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-bottom:1rem;
}
.rightPart{
    width:200px;
}
.rightPart fieldset{
    margin:0 !important;
}
.marginTop{
    margin-top:1rem;
}
.workingHoursConfigurator{
    display:flex;
    flex-direction: column;
    border:1px solid #ddd;
    margin-left:1rem;
    margin-bottom:1rem;
    gap:0.5rem;
}
.workingHoursConfiguratorHeader{
    margin-left:1rem;
}
.workingHoursRow{
    display:flex;
    align-items: center;
    padding:0 2rem;
    height:35px;
    gap:1rem;
}
.workingHoursRow:not(.workingHoursRow:last-child){
    border-bottom:1px solid #ddd;
}
.dayLabel, .isWorkingDay, .workingDayFrom, .workingDayTo{
    flex:1;
}
.workingLabel{
    margin-left:1rem;
}
.infoMessage{
    margin-left:1rem;
    color:orange;
}

.fileIntentImportContainer{
    display:flex;
    flex-direction: column;
    margin-bottom:1rem;
}
.fileIntentImportheader{

}
.fileIntentImportBody{
    display:flex;
}

.typeLabel{
    padding: 7px 15px;
    background: rgba(255, 193, 7, 1);
    border-radius: 5px;
    color: #fff;
    text-transform: uppercase;
}
.typeLabelChat{
    background: rgb(7, 255, 210);
}
.labelTypeContainer{
    margin-left:auto;
    margin-right: -10px;
}
.datasourceResponseResult{
    width: 100%;
    margin-left: 8px;
    border: 1px solid #ccc;
    height: 300px;
    padding:0.5rem !important;
}
.loaderContainer{
    display:flex;
    align-items: center;
    width: 40px;
    height: 40px;
}
.loader {
    width: 30px;
    height: 30px;
    border: 5px solid var(--buttonBlue);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
.marginLeft{
    margin-left:auto;
}

.datasourceRow{
    display:flex;
    justify-content: space-between;
    width:100%;
    gap:0.5rem;
}

.datasourceRowValue{
    flex: 1;
    margin-right: auto;
}
.datasourceRowOption{
    padding-left:20px;
}
.textInputField{
    background: white;
    border: 1px solid #bbb;
    padding: 0.6rem;
    margin-top: 0.8rem;
}
.newSource{
    background:#eee;
}
.datasourceRowAdd{
    display: flex;
    align-items: center;
}
.sources{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.flex{
    display:flex;
    margin-left:auto;
}
.margin{
    margin:0 1rem;
}
.border{
    padding:1rem;
    border:1px solid black;
}