.menuItem{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    background: #eee;    
    font-size: 1rem;
    color: gray;
    border-radius:50%;
    cursor:pointer;
}
.menuItem:last-child{
    margin-bottom:0;
}
.menuItem svg{
    margin: -2.5px;
}

.headerIcon{
    display:flex;
}
.headerIcon .hide{
    width:0;
}
.headerIcon .logo{
    height:25px;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    font-size: 3rem;
    color: var(--buttonBlue);
    margin-top:0.2rem;
    width:30px;
}

.menuItem{
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: rotate(180deg);
    margin-left:auto;
}
.rotate{
    transform: rotate(0deg);
}
