.agentStatus{
    color:#444;
    font-weight: 600 !important;
}
.agentStates{
    display:flex;
}
.agentIsActive{
    color:#28a745!important;
}
.agentInactive{
    color:#dc3545!important;
}
ul{
    border-right:1px solid #444;
    margin:0 1rem;
    padding:0 1rem;
}
ul:last-child{
    border:none;
}
.agentStatus span{
    font-weight: 600 !important;
    margin-right:1rem;
}