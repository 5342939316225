
.intentResponseLanguage {
    flex: 1 1;
    border-radius: 5px;
    font-weight: 600;
    /*background: rgb(243, 247, 251);*/
    background:white;
    width:50%;
    color: #444;
    padding: 1rem 2rem;
}
.responseLarge{
    width:100%;
}
.intentResponseLanguageTitle {
    padding: 0 1rem;
    display: flex;
    margin-bottom:1rem;
}

.intentResponseLang {

}

.intentResponseSigns {
    color: var(--buttonBlue);
    font-weight: 500;
    margin-left: auto;
}


.saved{
    font-size: 1.3rem;
    margin-left: 1rem;
    color:var(--buttonBlue);
}
.notSaved{
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-top:-1px;
    color:orange;
}
.listen {
    margin-top: 2px;
    margin-left: 1rem;
    cursor: pointer;
}
.warningColor{
    color:orange;
    cursor:pointer;
}

.warningColor:hover::after{
    content: attr(data-tooltip);
    position:absolute;
    color:#fff;
    background:#222;
    padding:0.5rem 1.4rem;
    border-radius:5px;
    font-size:1.2rem;
    margin-left:10px;
    margin-top:-2px;
}
.intentResponseLanguage textarea {
    width: 100%;
    padding: 1rem;
    height: 20ch;
    resize: none;
    overflow-y: scroll;
    border: 1.5px solid #eee;
    border-radius: 5px;
}
h1 {
    color: #444;
}

h3 {
    color: gray;
}
.saveBtn{

}
.highlight{
    color:var(--buttonBlue);
}
.stopPlaying{
    color:orange;
}

.bold{
    font-weight:600;
}
.intentResponseLanguageLabel{
    color:rgb(0, 41, 103);
    font-weight:700;
}
