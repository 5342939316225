
.widget {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    display:flex;
    flex-direction: column;
    width:50%;
}
.buttonRow{
    display:flex;
    align-items:center;
    gap:1rem;
    margin-bottom:0.5rem;
}
.buttonRowHeader{
    flex:1;
}
input{
    width:100%;
    height:auto !important;
}
.buttonRowActionButton{
    display:flex;
    gap:1rem;
}
.button{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.chatboxOptionLine{
    display: flex;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: rgb(40, 40, 40);
    position:relative;
    align-items: center;
    margin-bottom:2rem;
}
.chatboxOptionSelector{

}
.chatboxOptionDescription{
    margin-top:-2px;
    position:relative;
    flex:1;
    display:flex;
    cursor:default;
}

strong{
    text-transform: uppercase;
}
.widgets{
    display:flex;
    gap:1rem;
}
/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    padding:0.2rem 1rem;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}
.dropdown {
    position: relative;
    display: inline-block;
    margin-left:auto;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}
.dropdown-content span:first-child{
    padding-top:0;
}
.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.dropdownContainer{
    margin-left:auto;
    display:flex;
    gap:1rem;
}

.widgetHeader{
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    border-bottom: 1.5px solid #eee;
    padding-bottom:1rem;
    gap:1rem;
    position: relative;
}
.headerTitle{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
    margin-right:auto;
    left:0;
    position:absolute;
}
.buttonStyle{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.submitButton{
}
.buttonStyle:hover, .buttonStyle:focus {
    background-color: var(--buttonHoverBlue);
}
.formPanel{
    padding:1rem;
    padding-left:0;
}
.formRow{
    display:flex;
    gap:1rem;
    justify-content: space-between;
}
.formColumn{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.formHeaderText{
    padding: 0.5rem;
    border-bottom: 1.5px solid #eee;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.dropdownContainer{
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}
.dropdownHeader{
    display: flex;
    justify-content: space-between;
}
.formElementContainer{
    flex: auto;
}
.wFull{
    width:100px;
    flex-grow:5;
}
.w100{
    width:100px;
    flex-grow:0;
}
.disabledButton{
    background:#eee;
    cursor:default;
}
.disabledButton:hover{
    background:#eee;
}
.buttons{
    display:flex;
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    gap:1rem;
}
.deleteButton{
    background:rgb(211, 47, 47);
    cursor:pointer;
}
.deleteButton:hover, .deleteButton:focus {
    background-color: rgb(180, 43, 43);
}
.formRowLeft{
    justify-content: left;
}
.buttonsNewFlow{
    display:flex;
    flex-direction: row-reverse;
    gap:1rem;
    margin-left:auto;
}
.dropdownContainer{
    margin-bottom:1rem;
    
}
.catalogCheckboxOptions{
    margin-left:1rem;
}
.extraOptions{
    margin-top:1rem;
    padding-top:1rem;
    border-top:1px solid rgba(0, 0, 0, 0.38);
}