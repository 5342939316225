.gptContainer{
    width:100%;
    height:100%;
    display:flex;
    gap:2rem;
}
.gptContainer .chat{
    height:100%;
    width:25%;
}
.gptContainer .template{
    height:100%;
    width:75%;
}
.chatContainer{
    height:95%;
    background:#fff;
    border:1px solid #555;
    border-radius:5px;
    display:flex;
    overflow:hidden;
    flex-direction: column;
    overflow-y:scroll;
}
.templateContainer{
    height:95%;
    border: 1px solid #555;
    border-radius:5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.templateMenu{
    margin-bottom:0;
    height:60px;
    width:100%;
    padding:1rem 0.5rem;
    background:#fff;
    display:flex;
    align-items: center;
    gap:0.5rem;
    border-bottom:1px solid #333;
}
.chatInput{
    margin-top: auto;
    margin-bottom:0;
    height:50px;
    width:100%;
    padding:1rem 0.5rem;
    background:#eee;
    display:flex;
    align-items: center;
    gap:0.5rem;
}

.gptContainer textarea{
    width:100%;
    height:100%;
    border:none;
}
.gptContainer textarea:focus{
    outline:none;
}
.gptContainer input{
    border-radius: 50px;
    background:#fff;
    padding:0 2rem;
}
.instructionsTextarea{    
    padding:1rem;
}
.chatSubmit, .chatClear, .templateDelete, .templateSave, .templateNew{
    padding:0.5rem 1rem;
    border-radius:10px;
    background:#b6239c;
    color:#fff;
    cursor:pointer;
}
.chatClear, .templateDelete{
    background:#333;
}
.chatMessagesContainer{
    padding:1rem;
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.chatMessage{
    background:#c3dfff;
    width:75%;
    padding:1rem;
    border-radius:5px;
    position: relative;
}
.user{
    margin-left:auto;
    background:#9ad5d6;
}
.removeIcon{
    font-size:1.5rem;
    margin-left:auto;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor:pointer;
}

.templateNew{
    display:flex;
}
.buttonsRight{
    margin-left:auto;
    display:flex;
    gap:0.5rem;
}
.widgetHeader{
    width:500px;
}
.infoIconScale{
    font-size: 20px;
    cursor: pointer;
    margin-top:10px;
    margin-left:10px;
}
.includeEntities{
    height: 30px !important;
    border: 1px solid #bbb !important;
    border-radius: 5px !important;
    padding:5px !important;
}