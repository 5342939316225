/* Dropdown Button */
.dropbtn {
  background-color: var(--buttonBlue);
  color: white;
  padding: 7px 15px;
  font-size: 1.3rem;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  width: 100%;
  text-transform: uppercase;
  height: 46.5px;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
  border: none;
  background: #eee;
  border-radius: 2rem;
  outline: none;
  width: 100%;
  color: gray;
  height: auto;
  margin: 0 !important;
  font-size: 1.1rem;
  padding: 1rem 1.3rem;
}

.optionsAgentSelection {
  margin-bottom: 1rem;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}
.minHeightRow {
  min-height: 1rem;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 100%;
  border: 1px solid #ddd;
  z-index: 1;
  flex-wrap: wrap;
  padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: 12px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.dropdown-content span:last-child {
  padding: 12px 12px 0;
  border-bottom: 0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  color: var(--buttonBlue);
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: flex;
}

.dropdownIcon {
  margin-left: 10px;
  transition: all 0.3s;
}
.rotate {
  transform: rotate(180deg);
}
.noResult {
  cursor: default !important;
  color: black !important;
}
.optionsHeader {
  font-size: 1.5rem;
  border-bottom: 1.5px solid #eee;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  display: flex;
}

.optionsIcon {
  margin-right: 0.5rem;
  font-size: 2rem;
}

.integrationSelectionContainer {
  display: grid;
  width: 100%;
  align-items: start;
  padding-top: 1rem;
  gap: 0.5rem;
  grid-template-columns: repeat(6, [col-start] 1fr);
}
.row .inputSearch {
  border: none;
  background: #eee;
  border-radius: 2rem;
  outline: none;
  width: 100%;
  color: gray;
  height: auto;
  font-size: 1.1rem;
  padding: 1rem 1.3rem;
  margin: 0 0 1rem !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}
.publicDraftStyleDefaultBlock {
  margin: 0px !important;
}
.workingHoursConfigurator {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin-left: 1rem;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
.workingHoursConfiguratorHeader {
  margin-left: 1rem;
}
.workingHoursRow {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  height: 35px;
  gap: 1rem;
}
.workingHoursRow:not(.workingHoursRow:last-child) {
  border-bottom: 1px solid #ddd;
}
.dayLabel,
.isWorkingDay,
.workingDayFrom,
.workingDayTo {
  flex: 1;
}
.workingLabel {
  margin-left: 1rem;
}
