.searchContainer{
    display: flex;
}
.search{
    color: var(--buttonBlue);
    z-index:1;
}

.menuItem{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    background: #eee;    
    font-size: 1rem;
    color: gray;
    border-radius:50%;
    cursor:pointer;
}
.menuItem:last-child{
    margin-bottom:0;
}
.menuItem svg{
    margin: -2.5px;
}
input{
    height: 3rem;
    border:none;
    background: #eee; 
    flex-grow: 1;
    border-top-right-radius:2rem;
    border-bottom-right-radius:2rem;
    outline:none;
    z-index:0;
    width:0;
    color:gray;
}
.inputPaddingLeft{
     padding-left: 15px;
 }
