
.intentSelectorContainer{
    display:flex;
    flex-direction: column;
    height:100%;
    padding: 1.5rem;
    width:400px;
}
.editIntentContainerWidth{
    width:0px;
    padding: 0;
    display:none;
}

.addNewIntent{
    background-color: var(--buttonBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:1rem;
    text-transform: uppercase;
}
.addNewIntent:hover {
    background-color: var(--buttonHoverBlue);
}

.plusIcon{
    margin-right:1rem;
}

.category{

}
.categoryName{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
}
.intentName{
    font-size: 1.3rem;
    padding-left:2rem;
    cursor:pointer;
    border-radius:5px;
    padding-top:2px;
    padding-bottom:2px;
    position:relative;
    display:flex;
    align-items:center;
}
.newAddedIntent:after{
    content:'NEW';
    position:absolute;
    font-size:0.8rem;
    color:#fff;
    width:auto;
    height:auto;
    padding:0.2rem 0.5rem;
    border-radius:7px;
    background:rgba(62, 142, 65, 0.6);
    margin-left:0.5rem;
    margin-top:2px;
}
/* .intentSelected:hover .intentName:after{
    content:attr(data-id);
    position:absolute;
    font-size:1rem;
    color:#fff;
    width:auto;
    height:auto;
    padding:0.4rem 1rem;
    background:#444;
} */
.nameId{
    font-size: 1rem;
    color: #aaa;
    margin-top: -4px;
    font-weight: 100;
}
.popular:before{
    content:'';
    position:absolute;
    width:1rem;
    height:1rem;
    color:#fff;
    padding:0.2rem 0.5rem;
    border-radius:7px;
    background:rgba(62, 142, 65, 0.6);
    margin-left:-1.3rem;
}

.newIntentSearchInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
    margin-bottom:1rem;
    height:auto;
    flex:0;
}
.categoryContainer{
    overflow-y:scroll;
    flex:1;
}
.intentNumber{
    color: #bbb;
    font-weight: 100;
    font-size: 1.4rem;
    position:relative;
    top:-1px;
}
.hideRow{
    opacity:0.15;
    cursor:default;
}
.disable{
    cursor:default;
    background:#ccc;
}
.disable:hover{
    background:#ccc;
}
.intentsCount{
    margin-left: auto;
    margin-bottom: 5px;
    color: rgb(0, 41, 103);
    margin-right: 5px;
}
.triggerCount{
    margin-left: auto;
    color:#b6239c;
    margin-right: 10px;
}
.nameLabel{
}
.intentSelectorFilter{
    display:flex;
    align-items:center;
}
.onlyRecommendations{
    display: flex;
    align-items: center;
}
.onlyRecommendationsLabel{
    cursor: pointer;
}
.statusIcon{
    margin:0 7px 0 0;
    width: 15px;
    height: 15px;
    background: #ddd;
    border-radius: 3px;
    color: #fff !important;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    cursor:pointer;
    position: relative;
}
.checked{
    background: #007bff;
}
.statusIcon:hover:after{
    content: attr(data-tooltip);
    position: absolute;
    background:#444;
    padding:0.5rem 1rem;
    border-radius:5px;
    left:40px;
    z-index:1;
    font-size:1.2rem;
    width:200px;
}
.intentRow{
    justify-content: start;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    width: 100%;
}
.intentRow input{
    height:100% !important;
    cursor:pointer;
}

.intentRow:hover{
    background:rgb(243, 247, 251);
}
.intentSelected{
    background:rgb(243, 247, 251);
    font-weight:700;
}

.formRow{
    display:flex;
}