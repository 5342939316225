.nav{
    background:#fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    display:flex;
    align-items:flex-start;
    flex-direction: column;
    overflow-y:scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230,230,230,1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230,230,230,1);
    box-shadow: 0px 0px 8px 3px rgba(230,230,230,1);
    z-index:100;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
    display: none;
}
header, .searchdiv{
    width: 100%;
    padding:1.5rem;
}
header{
    border-bottom:1.5px solid #eee;
    height:6vh;
    padding: 0 1.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.searchdiv{
    padding-bottom:0 !important;
}
.navout{
    flex-grow:2;
}
.navout .searchdiv{
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    padding: 1.5rem 0.8rem 0.8rem;
}
.navout .search{
    border-radius: 6px !important;
}
.searchContainer{
    display: flex;
}
.search{
    color: var(--buttonBlue);
    z-index:1;
}
section{
    display:flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding:0.8rem;
    border-bottom:1.5px solid #eee;
    color:gray;
}
section:last-child{ 
    border-bottom:none;
}
.sectionTitle{
    width: 100%;
    font-size: 0.8rem;
    color:#222;
    margin-top: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    text-align:center;
}
footer{
    display:flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0.2rem 0.8rem;
    color:gray;
    align-items: flex-end;
    margin-top:auto;
    color:#b6239c;
    background:#fbe2f7;
}
