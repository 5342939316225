.configuration {
    display: grid;
    grid: auto minmax(0, 1fr) minmax(0, 1fr) / minmax(0, 1fr) minmax(0, 1fr);
    gap: 1.5rem;
    /* grid-template-areas: "header header header header" "optionmenu optionmenu optionmenu optionmenu" "agentsContainer intents editIntent addIntent"; */
    grid-template-areas: "header header" "users agents" "customers agents";
    transition: all 1s ease;
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.users{
    grid-area: users;
    height:100%;
    position: relative;
}
.customers{
    grid-area: customers;
    height:100%;
    position: relative;
}
.agents{
    grid-area: agents;
    height:100%;
    position: relative;
}

.widgetHeader{
height:100%;

overflow-y:scroll;
overflow-x:hidden;
}