
.agentCard {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    padding: 1rem 0;
    /*border-bottom: 1.5px solid #eee;*/
}

.agentCard:last-child {
    border-bottom: none;
}

.agentCard:hover .icon {
    background-color: var(--buttonBlue);
    color: #fff;
}

.icon {
    display: flex;
    justify-content: center;
    padding: 2rem;
    width: 2rem;
    height: 2rem;
    align-items: center;
    border-radius: 50%;
    text-transform: uppercase;
    color: var(--buttonBlue);
    font-weight: 500;
    font-size: 1.1rem;
    background-color: #fbe2f7;
    transition: background-color .5s ease;
}

.data {
    width: 100%;
}
.title {
    font-size: 1.5rem;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.info {
    display: flex;
    justify-content: space-between;
}

.langs {
    display: flex;
    flex-direction: row-reverse;
}

.lang {
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 0.5rem;
    position: relative;
    top: -1px;
    background: red;
    width: 2rem;
    height: 2rem;
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 0.5rem 0.5rem 5px;
    line-height: 1.2;

}

.lang:nth-child(1) {
    background-color: #957DAD;
}

.lang:nth-child(2) {
    background-color: #F7D4BC;
}

.lang:nth-child(3) {
    background-color: #706FBB;
}

.createdDate {
    font-size: 1rem;
    color: #aaa;
    position: relative;
    top: -3px;
}
.agentCard{
    color:rgb(0, 41, 103);
    font-size:1.5rem;
    padding: 0.7rem 1rem;
    border-radius:7px;
}
.agentCard:hover{
    background:rgb(243, 247, 251);
}

.selected{
    font-weight:700;
    background:rgb(243, 247, 251);
}

.categoryIcon{

}
.categoryName{

}
.categoryNumber{
    color:#bbb;
    font-weight:100;
    font-size:1.4rem;
}
