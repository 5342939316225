
.createAgentButton {
    padding: 2rem;
    grid-area: newagent;
    background: var(--buttonBlue);
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
    font-size: 1.5rem;
    font-weight: 500;
}

.list {
    padding: 1.5rem;
}

.flipCardInner {
    width: 100%;
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    flex-direction: column;
    display: flex;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipped {
    transform: rotateY(180deg);
}

.flipped .flipCardBack {
    z-index: 10;
}

/* Position the front and back side */
.flipCardFront, .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipCardFront {
    z-index: 5;
}

/* Style the back side */
.flipCardBack {
    transform: rotateY(180deg);
}


.newAgent {
    flex: auto;
    grid-area: newagent;
    background: var(--buttonBlue);
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s ease;
}


.agentList {
    width: 95%;
    /*height:100%;*/
    display: flex;
    flex-direction: column;
    transition: height 0.5s ease;
    flex:1;
}


.agentNumber {
    font-weight: 600;
    margin-top: 1rem;
    color: #444;
    margin-bottom: 1rem;
}

h1 {
    color: #444;
}

h3 {
    color: gray;
}
.newAgentSearchInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
    margin: 0 0 1rem !important;
}


.integrationRow{
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 1.3rem;
    padding-bottom: 2px;
    padding-left: 1rem;
    padding-top: 2px;
    position: relative;
}
.addNewWebhook{
    background:var(--buttonBlue);
    cursor:pointer;
    color:#fff;
    width:100%;
    padding:0.7rem;
    text-align:center;
    border-radius:5px;
    margin-top:0.5rem;
    text-transform: uppercase;
}
.listSubTitle{
    width:100%;
}
.selectedIntegrationRow{
    color:var(--buttonBlue);
}
.integrationHeader{
    color: #002967;
    font-size: 1.5rem;
    cursor:pointer;
}