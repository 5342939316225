.integrations {
    display: grid;
    grid: auto auto minmax(0, 1fr) / 3fr 3fr;
    gap: 1.5rem;
    grid-template-areas: "header header" "agentSelection integrationDetailContainer" "integrationSelection integrationDetailContainer";

    transition: all 1s ease;
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.agentSelection{
    grid-area: agentSelection;
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
    padding:1rem;
}
.integrationSelection{
    grid-area: integrationSelection;
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
    padding:1rem;
    flex-direction: column;
}

.integrationDetailContainer{
    grid-area: integrationDetailContainer;
    height:100%;
    position: relative;
    display: flex;
    align-items: start;
    padding:1rem;
    flex-direction: column;
}


/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    width:100%;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    width:100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 100%;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}

.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.noResult{
    cursor:default !important;
    color:black !important;
}
.optionsHeader {
    font-size: 1.5rem;
    border-bottom: 1.5px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 500;

}

.optionsIcon {
    margin-right: 0.5rem;
    font-size: 2rem;
}

.integrationSelectionContainer{
    display: grid;
    width:100%;
    align-items: start;
    padding-top:1rem;
    gap:0.5rem;
    grid-template-columns: repeat(6, [col-start] 1fr);
}
.row .inputSearch{
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
    margin: 0 0 1rem !important;
}

.integrationSelectionItem{
    min-height: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    height:130px;
    padding: 0.3rem;
    min-width: 0;
    cursor:pointer;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
.highlightIntegration, .integrationSelectionItem:hover{
    border: 1px solid var(--buttonBlue);
}
.integrationSelectionItem img{
    width:100%;
}
.row{
    display:flex;
    width:100%;
}
.integrationName{
    width:100%;
    text-align: center;
}

.integrationSummary{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-bottom:1rem;
}
.integrationSummaryName{
    display:flex;
}
.integrationSummaryDescription{

}
.conditionRow{
    display:flex;
    align-items: center;
    border: 1px solid #ddd
}
.requiredFieldLabel{
    flex:1;
    padding-left:1rem;
}
.requiredFieldInput{
    flex:2;
    padding-left:1rem;
    border-left: 1px solid #ddd;
    padding:1rem;
}
.requiredFieldInput input{
    width: 100%;
    background: #fff;
}
.installed{
    margin-left:auto;
    background:#b6239c;
    color:#fff;
    padding:1rem;
    align-items: center;
    border-radius:5px;
}
.installButton{
    margin-left:auto;
    background:var(--buttonBlue);
    color:#fff;
    padding:1rem;
    align-items: center;
    border-radius:5px;
    cursor:pointer;
}
.installButton:hover{
    background:#862373;
}
.btnDisabled{
    cursor: not-allowed;
    background:#ddd;
}
.btnDisabled:hover{
    background:#ddd;
}