
.dictRow{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #eee;
}
.dictRow:last-child{
    border-bottom: none;
}
.dictRowKey{
    flex:0.5;
    border-right:1px solid #eee;
    padding:0.5rem;
}
.dictRowValue{
    flex:1.5;
    border-right:1px solid #eee;
    padding:0.5rem;
}
.dictRowDelete{
    flex:0.2;
    padding:0.5rem;
    justify-content: center;
    align-items: center;
    display:flex;
}
.dictInput{
    background:#fff;
    width:100%;
}
.dictHeaderRow .dictInput{
    font-weight:800;
}
.dictRowDelete .dictInput{
    justify-content: center;
    display:flex;
    align-items:center;
}
.deleteDictRowIcon{
    cursor:pointer;
    font-size:1.5rem;
}
.tabHeaderRow{
    margin-bottom:1rem;
}
.dictRow .dictInput{
    height:auto !important;
}