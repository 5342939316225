
.optionsHeader {
    font-size: 1.5rem;
    border-bottom: 1.5px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 500;

}

.optionsIcon {
    margin-right: 0.5rem;
    font-size: 2rem;
}

.optionsCompanyInfo {
    grid-area: companyInfo;
}

.optionsAgentInfo {
    grid-area: agentInfo;
}

.infoLine {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.infoLine .infoKey {
    font-weight: 500;
}

.infoLine .infoValue {

}

.optionsAgentActions .action {
    width: fit-content;
    background: var(--buttonBlue);
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-bottom: 0.5rem;
}
.upper{
    text-transform: uppercase;
}
.optionsAgentActions .warning {
    padding: 0.5rem 1.5rem;
    background: red;
    color: #fff;
}

.optionsAgentSelection {
    grid-area: agentSelection;
}

.optionsAgentActions {
    grid-area: agentActions;
}

h1 {
    color: #444;
}

h3 {
    color: gray;
}



/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonHoverBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    width:100%;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    width:100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 100%;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}

.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.noResult{
    cursor:default !important;
    color:black !important;
}




.firstAgentDialog{
    display:grid;
    grid: 250px / minmax(250px, 1fr) minmax(250px, 1fr);
    grid-template-areas:"firstAgent fields";
    gap:1rem;
}
.firstAgentContainer{
    grid-area: firstAgent;
    display:flex;
    flex-direction: column;
    justify-content:center;
}
.firstAgentContainerTitle{
    font-size:2.3rem;
    color:#444;
    font-weight: 500;
}
.firstAgentContainerSubTitle{
    font-weight: 300;
    color:#444;
}
.firstAgentContainerContent{
    
}
.fieldsContainer{
    grid-area: fields;
    display:flex;
    flex-direction: column;
    justify-content:center;

}
.firstAgentRowHeader{
    color:#bbb;
    font-weight: 300;
    margin-bottom:1rem;
    margin-top:1.5rem;
}
.firstAgentRow{
    font-weight: 300;
    margin-bottom:3px;
}
.valid{
    color:#28a745;
}
.invalid{
    color:#ddd;
}
.updateButtonPopup{
    margin-top:5px;
}
.formPanel{
    padding:1rem;
    padding-top:0;
    padding-left:0;
}
.formRow{
    display:flex;
    gap:1rem;
    justify-content: space-between;
}
.formElementContainer{
    flex: auto;
}
.updateButton{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-right: -8px;
    margin-left: 8px;
}
.disable{
    cursor:default;
    background:#eee;
}


.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 40px;
  }
  .ldsEllipsis div {
    position: absolute;
    top: 13px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background:  var(--buttonBlue);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldsEllipsis div:nth-child(1) {
    left: 8px;
    animation: ldsEllipsis1 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(2) {
    left: 8px;
    animation: ldsEllipsis2 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(3) {
    left: 32px;
    animation: ldsEllipsis2 0.6s infinite;
  }
  .ldsEllipsis div:nth-child(4) {
    left: 56px;
    animation: ldsEllipsis3 0.6s infinite;
  }
  @keyframes ldsEllipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ldsEllipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ldsEllipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
  .typeLabel{
    padding: 4px 15px;
    background: rgba(255, 193, 7, 1);
    border-radius: 5px;
    color: #fff;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.typeLabelChat{
    background: rgb(7, 255, 210);
}
.labelTypeContainer{
    margin-top: 0.5rem;
    text-align: center;
}