.newAgentSearchInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 50%;
    color: gray;
    height: auto;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}
.searchContainer{
    display: grid;
    /* grid: 1fr / 2fr 4fr 1fr 2fr 1fr; */
    grid: 1fr / 1fr 4fr 1fr 2fr;
    border-bottom: 1.5px solid #eee;
    border-radius: 5px;
    overflow:hidden;
    padding: 1.5rem 0;
    /* grid-template-areas: "gridName gridSearch gridStatus gridPopularity gridEdit"; */
    grid-template-areas: "gridName gridSearch gridStatus gridPopularity";
    height:70px;
}
.searchContainer div{
    padding: 0 2rem;
    font-size:1.6rem;
    color:#bbb;
    display:flex;
    align-items:center;
}
.searchContainer .sortIcon{
    margin:0 0.3rem;
    font-size:1.4rem;
    cursor:pointer;
}
.searchContainer .sortIcon:hover{
    color:#6849E4;
}
.searchContainer .sortIcon:nth-child(2){
    transform: rotate(180deg);
}
.gridName{
    grid-area: gridName;
}
.gridSearch{
    grid-area: gridSearch;
}
.gridStatus{
    grid-area: gridStatus;
    justify-content:center;
    cursor:pointer;
}
.gridPopularity{
    grid-area: gridPopularity;
}
.gridEdit{
    grid-area: gridEdit;
    margin-left:1rem;
}
.intentNumber{
    border: 1px solid #eee;
    font-size: 1rem !important;
    margin-left:0.5rem;
    padding:0 5px 0 0 !important;
}
.intentsTotal{
    padding: 0.5rem 1rem !important;
    font-size: 1rem !important;
    border-right:1px solid #eee;
}
.intentsActive{
    font-size: 1rem !important;
    /* border-right:1px solid #eee; */
    padding: 0.5rem !important;
}
.statusIcon{
    margin:0 7px;
    width: 15px;
    height: 15px;
    background: #ddd;
    border-radius: 3px;
    color: #fff !important;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    cursor:pointer;
    position: relative;
}
.isActive{
    background: var(--buttonBlue);
}
.inActive{
    background: #dc3545;
}
.inProgress{
    background: rgb(255, 193, 7);
}
.ready{
    background: #007bff;
}
.statusIcon:hover:after{
    content: attr(data-tooltip);
    position: absolute;
    background:#444;
    width:350px;
    padding:0.5rem 1rem;
    border-radius:5px;
    left:40px;
    z-index:1;
    font-size:1.2rem;
}
strong{
    margin-right:5px;
    color:#222;
}
.clearSearch{
    font-size:2rem;
    margin-left:0.5rem;
    cursor:pointer;
}
.clearSearch:hover{
    color:#6849E4;
}
.expandIcon{
    transform:translateX(-1rem);
    font-size:2rem;
    cursor:pointer;
}
.expandIcon:hover{
    color:#6849E4;
}
.expandIconActive{
    color:#6849E4;
}
.sortable{
    cursor:pointer;
}
.whereToBegin{
    padding:1rem;
}
.whereToBegin h1{
    margin-bottom:2rem;
}