.intentStructure {
    display: grid;
    grid: auto auto minmax(0, 1fr) / 2fr 2.5fr 2fr;
    gap: 1.5rem;
    grid-template-areas: "header header header" "catalogSelector intentDetails intentPhrases" "intents intentDetails intentPhrases";
    /* transition: all 1s ease; */
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.catalogSelector {    
    grid-area: catalogSelector;
    height:100%;
    position: relative;
    align-items: center;
    padding:1rem;
}
.intents {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* transition: height 0.5s ease; */
    grid-area: intents;
    padding: 0;
    overflow-y: scroll;
    perspective: 1000px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    padding:1rem 2rem;
}

.leftColumn::-webkit-scrollbar {
    display: none;
}
.intentDetails{
    grid-area: intentDetails;
    display: flex;
    flex-direction:column;
    overflow:hidden;
    gap:0.5rem;
    padding:2rem;
    overflow-y: scroll;
}
.intentPhrases{
    grid-area: intentPhrases;
    display: flex;
    flex-direction:column;
    overflow:hidden;
    gap:0.5rem;
    padding:1rem;
}
/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    width:100%;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}


/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 100%;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}

.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.noResult{
    cursor:default !important;
    color:black !important;
}
.optionsHeader {
    font-size: 1.5rem;
    border-bottom: 1.5px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 500;

}

.optionsIcon {
    margin-right: 0.5rem;
    font-size: 2rem;
}


.intentListRow{
    cursor:pointer;
    position:relative;
    font-size: 1.3rem;
    text-transform: capitalize;
}
.newIntentContainer{
    display:flex;
    flex-direction: column;
    gap:1rem;
}
.newIntentContainerRow{
    display:flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
}
.newIntentContainerFieldsHeader{
    margin-top:1rem;
    margin-bottom:0.5rem;
}
.newIntentContainerRow .fieldName{
    flex:1;
}
.newIntentContainerRow .fieldDescription{
    flex:3;
}
.newIntentContainerRow .fieldDefault{

}
.addNewFieldsRow{
    margin-top:0.5rem;
}
.optionsColumn{
    display:flex;
    flex-direction: column;
    gap:0.2rem;
}
.intentSelected{
    color:#b6239c;
    font-weight:500;
}
.intentInActive{
    color:#ccc;
}
.closeIcon{
    cursor:pointer;
}
.intentDetailsForm{
    display:flex;
    flex-direction: column;
}
.fieldsContainer{
    display: flex;
    flex-wrap: wrap;
}
.formGroup{
    width:100%;
    display:flex;
    flex-direction: column;
    gap:1rem;
}
.formRow{
    display:flex;
    gap:1rem;
}
textarea{
    padding: 0 !important;
}
.formRowRight{
    display:flex;
    justify-content: right;
}
.intentList{
    
}
.buttonStyle{
    background-color: var(--buttonBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    text-transform: uppercase;
    height:fit-content;
}
.buttonStyle:hover {background-color: var(--buttonHoverBlue)}
.actionRow{
    display:flex;
    justify-content: right;
    gap:0.5rem;
}
.childContextHeader{

}
.childContextTags{
    display:flex;
    flex-direction: column;
    gap:0.5rem;
    max-height: 150px;
    overflow-y:scroll;
    margin:0.5rem 2rem;
    padding:1rem;
}
.intentTag{
    background:#b6239c;
    padding:0.5rem 1.5rem;
    border-radius:50px;
    color:#fff;
    cursor:pointer;
}
.dropdowns{
    display:flex;
    flex-direction: row;
    justify-content: start;
    gap:1rem;
}
.phrasesTextArea{
    width:100%;
    height:100%;
    padding:1rem !important;
}
.phrasesTextArea:focus{
    outline:none;
}
.rightButton{
    position: absolute;
    right: 30px;
}
.intentId{
    font-size: 0.9rem;
    color: #aaa;
    margin-top: -4px;
    margin-bottom: -4px;
}
.languageSpecific{
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.23);
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.languageSpecific h3{
    margin-left:5px;
    margin-bottom:0;
    color:var(--buttonBlue);
}
.buttonNewCategory{
    position: absolute;
    right: 1rem;
    z-index:1000;
}
.textAreaStyle{
    border: 1px solid #ccc;
    padding:0.5rem !important;
    font-size:13px;
}
.textAreaStyle:focus{
    outline:none;
}
.flex{
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
}
.intentTrigger{
    border:2px solid #aaa;
    padding:1.5rem !important;
    background: #eee;
    display:flex;
    flex-direction: column;
    gap:1rem;
}