.subMenuItem{
    width:100%;
    height:30px;
    border-radius:6px;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:1.4rem;
    margin: 0.5rem 0;
    overflow:hidden;
}
.linkText{
    width: 0;
    flex-grow: 1;
    position:relative;
    left:8px;
    color:gray; 
    font-size: 1.1rem;
    left:15px;
    color: grey;
}
.tooltiptext {
    font-size:1.2rem;
    visibility: hidden;
    width: max-content;
    background-color: #222;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5rem 1.4rem;
    left:65px;
    margin-top: -20px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

.menuIn:hover .tooltiptext{
    visibility: visible;
}

.subMenuItem svg{
    margin-left: 1.6rem;
}
.subMenuItem:hover svg{
    transition: all 0.3s ease;
    color:#b6239c;
}

.subMenuItemActive{
    transition: all 0.3s ease;
    background:#fbe2f7;
    color:#b6239c;
}
.subMenuItemActive .linkText{
    color:#b6239c;
}
.updates{
    border-radius: 50%;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    background: #b6239c;
    top: -5px;
    left:-4px;
    color: #fff;
    text-align: center;
    padding-top:0.5px;
}
