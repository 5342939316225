.agent {
    display: grid;
    grid: auto auto minmax(0, 1fr) / minmax(15%, 1.6fr) 10fr auto auto;
    gap: 1.5rem;
    /* grid-template-areas: "header header header header" "optionmenu optionmenu optionmenu optionmenu" "agentsContainer intents editIntent addIntent"; */
    grid-template-areas: "header header header header" "optionmenu intents editIntent addIntent" "agentsContainer intents editIntent addIntent";
    /* transition: all 1s ease; */
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: space-between;
    display: flex;
    flex-direction: row;

}

.intents {
    grid-area: intents;
    overflow-y: scroll;
    overflow-x:hidden;
    flex-direction: column;
    gap: 1rem;
    /*-ms-overflow-style: none; !* IE and Edge *!*/
    /*scrollbar-width: none;*/
}
.editIntent{
    grid-area: editIntent;
    flex-direction: column;
    gap: 1rem;
    padding:0 !important;
    /* transition:all 1s; */
}
.addIntentContainer{
    grid-area: addIntent;
    flex-direction: column;
    gap: 1rem;
    padding:0;
    /* max-width:400px; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.intents::-webkit-scrollbar {
    /*display: none;*/
}

.agentsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* transition: height 0.5s ease; */
    grid-area: agentsContainer;
    padding: 0;
    overflow-y: scroll;
    perspective: 1000px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}

.agentsContainer::-webkit-scrollbar {
    display: none;
}


.optionMenu {
    grid-area: optionmenu;
    display: grid;
    /* grid: 1fr / 2fr 1fr 1fr 1fr; */
    /* grid-template-areas: "companyInfo agentSelection agentInfo agentActions"; */
    /* grid: 1fr / 1fr; */
    /* gap: 3rem; */
    grid-template-areas: "agentSelection";
    grid: 1fr;
    padding: 1rem;
}
