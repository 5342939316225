
.editIntentContainer{
    display:grid;
    grid: 5% 95% / 100%;
    grid-template-areas: "chatboxHeader" "chatboxContent";
    height:100%;
    width:0;
    /* transition:width 0.5s; */
    overflow:hidden;
    gap:1rem;
}
.notActiveContainerNotifier{
    border:5px solid #dc3545;
    border-color: #dc3545;
    border-radius: 10px;
    animation: glowing 3s infinite;
}
@keyframes glowing {
    0%  {border-color: rgba(255, 193, 7, 1);}
    50%  {border-color: rgba(255, 193, 7, 0.3);}
    100%  {border-color: rgba(255, 193, 7, 1);}
  }

.editIntentContainerWidth{
    width:800px;
    padding: 1.5rem;
}
.columnContainer{
    grid-area: chatboxContent;
    display:inline-flex;
    gap:1rem;
    height:100%;
    overflow:hidden;
    padding-bottom:1rem;
}
.close{
    font-size:2rem;
    cursor:pointer;
}
.chatboxHeader{
    grid-area: chatboxHeader;
    display:flex;
    align-items: center;
    gap: 1rem;
}
.editIntentName{
    flex:1;
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
    margin-right:auto;
}

.chatboxContent{
    display:flex;
    flex-direction: column;
    height:100%;
    flex:1;
    background: #f9f9f9;
}
.chatboxContainer{
    height:100%;
    overflow-y:scroll;
    padding:1rem 0;
    border: 1px solid #eee;
}
.dialogGroup{
    border: 1px dashed #ddd;
    position:relative;

    margin: 1rem 0.5rem;
}
.dialogGroupHeader{
    display:flex;
    padding: 0.5rem;
    background:#eee;
    margin-bottom:0.7rem;
}
.chatboxContentHeader{
    display:flex;
    justify-content: space-between;
    align-items:center;
}
.dialogGroupName{
    color: #444;
    font-size: 1rem;
    flex:1;
}
.dialog{
    padding: 0.7rem 1rem;
    width:70%;
    border-radius: 1rem 1rem 1rem 0;
    background: #fff;
    margin: 0 0.5rem 0.7rem 0.5rem;
}
.right{
    margin-left:auto;
    border-bottom-left-radius:1rem;
    border-bottom-right-radius:0;
    background: rgba(220,243, 254, 1);
}
.dialogResponseOptions{
    display:flex;
    margin-left:auto;
    justify-content: space-between;
    padding-right:1rem;
}
.editable{
    background:var(--buttonBlue);
    color:#fff;
    display:block;
    border:none;
    outline:none;
    overflow: hidden;
    height:150px;
}
.chatboxOptions{
    display:flex;
    flex-direction: column;
    gap:0.5rem;
    margin-top:auto;
    height: inherit;
    overflow:hidden;
    overflow-y:scroll;
    flex:1;
}
.chatboxOptions span{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
}
.chatboxOptionLine{
    display: flex;
    gap: 1rem;
    font-size: 1.3rem;
    color: rgb(40, 40, 40);
    position:relative;
    align-items: center;
}
.chatboxOptionSelector{

}
.chatboxOptionDescription{
    margin-top:-2px;
    position:relative;
    flex:1;
    display:flex;
    cursor:default;
}
.userPrompt{
    background:var(--buttonBlue);
    color:#fff;
}

/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    padding:0.2rem 1rem;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}
.dropdown-content span:first-child{
    padding-top:0;
}
.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.warningColor{
    color:orange;
    cursor:pointer;
}

.warningColor:hover::after{
    content: attr(data-tooltip);
    position:absolute;
    color:#fff;
    background:#222;
    padding:0.5rem 1.4rem;
    border-radius:5px;
    font-size:1.2rem;
    margin-left:10px;
    margin-top:-2px;
}
.listen {
    margin-top: 2px;
    margin-left: 1rem;
    cursor: pointer;
}
.actionBtn{
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    color: white;
    flex:1;
    text-transform: uppercase;
}
.saveBtn{
    background-color: var(--buttonBlue);
    flex:2;
}
.saveBtn:hover {
    background-color: var(--buttonHoverBlue);
}
.deleteBtn{
    background-color: #e05260;
}
.deleteBtn:hover {
     background-color: #dc3545;
 }
.actionIcon{
    margin-right:1rem;
    font-size:2rem;
}
.actionContainer{
    display:flex;
    gap:1rem;
    margin-top:auto;
}
.notEditable{
    color:#fff;
    background:orange;
    padding:0.5rem 1.4rem;
    border-radius:5px;
    font-size:1.2rem;
    margin-top:-2px;
    margin-right:10px;
}
.infoIcon{
    margin-left:2px;
    cursor:help;
}
.infoIcon:hover:after{
    content: attr(data-tooltip);
    position:absolute;
    background:#444;
    right: 0;
    bottom:2.5rem;
    color:#fff;
    padding:0.5rem 1rem;
    border-radius:1rem;
    z-index:1;
    font-size:1rem;
}
.sendSmsAttachment{
    display:flex;
    flex-direction: column;
}
.sendSmsAttachment label{
    display:flex;
    margin-bottom:0.5rem;
}
.sendSmsAttachment  textarea{
    margin-left:auto;
    width:80%;
    resize:none;
    border:1px solid rgba(0, 0, 0, 0.23);
    padding:0.5rem !important;
}
.sendSmsAttachment  textarea:focus{
    outline:none;
}
.disabledLine{
    opacity:0.3;
    cursor: not-allowed;
}
.disabledLine > * {
    cursor: not-allowed;
}
.chatboxContentHeader .dropdown{
    margin-right:10px;
}
.textArea{
    width:100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding:0.5rem !important;
}
.textArea:focus{
    border: 1px solid rgba(0, 0, 0, 0.23);
    outline:none;
}
.titleId{
    font-size: 0.9rem;
    color: #aaa;
    margin-top: -4px;
    font-weight: 100;
}
.channel{
    margin-right:1rem;
}
.fieldsContainer{
    display: flex;
    flex-wrap: wrap;
}
.formRow{
    display:flex;
}
.formRow span{
    font-weight: 400;
    font-size:1.3rem;
    color:rgb(40, 40, 40);
}
.formRow span span{
    color:#333392;
}
.fieldRow{
    width:100%;
    margin-left:15px;
}
.subHeader{
    margin-top:1rem;
    font-size:1.5rem !important;
}
.endingDescription{
    color:gray;
    font-weight: 100;
}

ul li{
    height:27px;
}
.generateBtn{
    padding:0;
    margin-top:0;
}
.loader {
    width: 30px;
    height: 30px;
    border: 5px solid var(--buttonBlue);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 