/* Dropdown Button */
.dropbtn {
    background: #eee;
    color: #444;
    padding: 7px 15px;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    width:100%;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: #ddd;
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1rem;
    padding: 0.5rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    width:100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    width:250px;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    font-size:1rem;
    border-bottom:1px solid #eee;
}

.dropdown-content span:last-child{
    /* padding: 12px 12px 0; */
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex; z-index:10;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.noResult{
    cursor:default !important;
    color:black !important;
}
.disabled .dropbtn{
    cursor:default;
    color: #bbb;
}
.disabled .dropbtn:hover, .disabled .dropbtn:focus {
    cursor:default;
    color: #bbb;
    background:#eee;
}
.subGroup{
    width:100%;
}
.subGroup span{
    padding: 0.5rem 1rem;
    border:none !important;
    margin-left:1rem;
    font-size:1.2rem;
}
.subHeader{
    margin-left:1rem;
    border-bottom: 1.5px solid #aaa;
    font-size: 1.8rem;
}
.highlightSelectedRow{
    font-weight: 600;
    color:var(--buttonBlue) !important;
}