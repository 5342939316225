.entityGrid{
    display:grid;
    grid: 100% / minmax(0, 0.7fr) minmax(0, 1fr) minmax(0, 1fr)  ;
    gap: 1.5rem;
    grid-template-areas: "entities synonyms updateSummary";
    height: 100%;
}
.widget {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.entities{
    grid-area: entities;
    height:100%;
    position: relative;
    overflow:hidden;
    display: flex;
    flex-direction: column;
}

.synonyms{
    grid-area: synonyms;
    height:100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.updateSummary{
    grid-area: updateSummary;
    height:100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.count{
    margin-left: auto;
    margin-bottom: 5px;
    color: rgb(0, 41, 103);
    margin-right: 5px;
    justify-content: end;
    display: flex;
}
.entityRow, .synonymRow{
    font-size: 1.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    border-radius: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
}
.synonymRow{
    display:flex;
    cursor: default;
    background: var(--buttonBlue);
    color: white;
    border-radius: 30px;
    align-items:center;
    padding-right:1rem;
}
.synonymRow:hover{
    background:var(--buttonHoverBlue);
}
.synonymRow svg{
    cursor:pointer;
    margin-left:auto;
    font-size:2rem;
}
.synonymContainer, .phrasesContainer, .summaryContainer{
    overflow-y:scroll;
    flex:1;
}
.newIntentSearchInput, .newSynonymInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
    margin-bottom:1rem;
    height:auto;
    flex:0;
}
.newSynonymInput{
    width:100%;
}
.selected{
    font-weight: 600;
    color: #DB0EB7;
    font-size:1.3rem;
}
.typeSymbol{
    margin-left: 0 !important;
    margin-right: 1rem;
    cursor:default !important;
}
.summaryHeader{
    margin-top:1rem;
    font-size:1.5rem;
    font-weight:600;
}
.buttons{
    display:flex;
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    gap:1rem;
}
.buttonStyle{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.submitButton{
}
.buttonStyle:hover, .buttonStyle:focus {
    background-color: var(--buttonHoverBlue);
}
.disabledButton{
    background:#eee;
    cursor:default;
}
.disabledButton:hover{
    background:#eee;
}
.loadingPosition{
    width:50px;
    height:50px;
    margin-left: auto;
    margin-top: 50px;
    display: block;
    margin-right: auto;
}
.searchContainer{
    display: grid;
    grid-auto-flow: column;
    align-items:center;
}
.typeButtons{
    margin-left:auto;
    display: flex;
    gap: 0.5rem;
    margin-top:-15px;
}
.button{
    background-color: #ddd;
    color: black;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    padding:0.2rem 1rem;
    height: max-content;
}
.button:hover{
    background-color: #eee;
}
.activeButton{
    background-color: var(--buttonBlue);
    color: white;
    text-transform: uppercase;
}
.button:hover{
    background-color: var(--buttonBlue);
    color: white;
}
.valueContainerRow{
    display:flex;
    align-items: center;;
}
.valueContainerRow span{
    flex:1;
    color: rgb(0, 41, 103);
    font-size: 1.5rem;
    margin-top: -1rem;
}
.valueContainerRow input{
    flex:2;
}
.synonymsContainer{
    display:flex;
    gap:0.5rem;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 10px;
    flex-flow: wrap;
}
.valueSynonymContainer:not(:first-child){
    padding-top:10px;
}
.scrollContainer{
    overflow-y:scroll;
    height: 100%;
}
.synonymValueContainer{
    display:flex;
    flex-flow: wrap;
    gap:0.5rem;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 10px;
}
.synonymValueContainerTitle{
    width:100%;
}
.synonymsContainerUpdate{
    display:flex;
    gap:0.5rem;
    padding-bottom: 10px;
    flex-flow: wrap;
    flex-direction:column;
}
.synonymValueContainerSynonyms{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.noFlex{
    display:block;
}