.snackbarContainer{
    display:flex;
    flex-direction: column;
    position:absolute;
    right:0;
    top:30px;
    gap:0.5rem;
    transition: all 0.5s;
}
.loading{
    margin-right: 1rem;
    flex-direction: row;
    align-items: center;
    display: flex;
}
.loadingText{
    font-weight: 600;
}
.ldsRipple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(0.5);
    margin-top:5px;
    margin-right:-15px;

  }
  .ldsRipple div {
    position: absolute;
    border: 4px solid var(--buttonBlue);
    opacity: 1;
    border-radius: 50%;
    animation: ldsRipple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .ldsRipple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes ldsRipple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  