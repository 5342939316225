/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --buttonBlue: #333392;
    --buttonHoverBlue: #2e2e7b;
    /* --buttonBlue: #474CD6;
    --buttonHoverBlue: #4246ba; */
  }

html{
    font-size:62.5%;
}

body{
    /*font-family: 'Open Sans', sans-serif !important;*/
    color:var(--buttonHoverBlue);
    font-size: 12px !important;
    overflow:hidden;
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 300;
}

.bg {
    /* background-image: url("/public/shattered-island.gif"); */
    background-image: url("/public/background_dark.jpg");
    background-size: 140%;
    background-repeat: both;
    background-position-x: right;
    /* background-repeat: no-repeat; */
    background-color: var(--buttonBlue);
}
h1{
    font-weight: 800 !important;    
    font-size:24px !important;
}
h2{
    font-weight: 500 !important;    
    font-size:18px !important;
}
h3{
    font-weight: 400 !important;    
    font-size:14px !important;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: var(--buttonBlue) !important;
    border:none !important;
    text-transform: uppercase;
}
.btn-outline{
    background:none !important;
    border:1px solid var(--buttonBlue) !important;
    color: var(--buttonBlue) !important;
    font-size:12px !important;
}
.btn-outline:hover{
    background:none !important;
    border:1px solid var(--buttonBlue) !important;
    color: var(--buttonBlue) !important;
}
.application-description{
    line-height: 32px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}
.white{
    color: #ddd;
}
.dark{
    color:#444!important;
}
.large{
    font-size:30px !important;
}
.dark-bg{
    background-color: var(--buttonHoverBlue) !important;
}
.dark-bg-2{
    background-color: #222 !important;
}


ol .list-group-item{
    /* background-color: #424242 !important; */
    color: #858585 !important;
    cursor:pointer;
}

ol .list-group-item:hover{
    background-color: #565656 !important;
}
#basic-addon1{
    justify-content: center;
}

.synonym-input-field{
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid black;
}
.word{
    background: #0d6efd;
    color:white;
    border-radius: 17px;
    padding: 5px 14px;
    margin-right: 5px;
    cursor:pointer;
}
.word:hover{
    background:red;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #fff #eee;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #eee;
}

*::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 20px;
    border: 3px solid #eee;
}
.confirm-box{
    left:40% !important;
}
strong{
    color:var(--buttonBlue);
}
.brainHeading{
    display: flex;
    justify-content: center;
}
.aiHeading{
    color:var(--buttonBlue);
}
.select__multi-value{
    color:#fff !important;
}
.select__multi-value div{
    color:#fff !important;
}
.select__multi-value div:hover{
    background: transparent !important;
    color:#fff !important;
}