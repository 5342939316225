.callback {
    display: grid;
    grid: auto auto minmax(0, 1fr) / 1fr 4fr;
    gap: 1.5rem;
    grid-template-areas: "header header" "agentSelection grid" ". grid";
    transition: all 1s ease;
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.agentSelection{
    grid-area: agentSelection;
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
    padding:1rem;
}
.grid {
    grid-area: grid;
    overflow-y: scroll;
    overflow-x:hidden;
    flex-direction: column;
    gap: 1rem;
    /*-ms-overflow-style: none; !* IE and Edge *!*/
    /*scrollbar-width: none;*/
}

/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    padding: 7px 15px;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    width:100%;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    width:100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 100%;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}

.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.noResult{
    cursor:default !important;
    color:black !important;
}
.optionsHeader {
    font-size: 1.5rem;
    border-bottom: 1.5px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 500;

}

.optionsIcon {
    margin-right: 0.5rem;
    font-size: 2rem;
}


.typeLabel{
    padding: 4px 15px;
    background: rgba(255, 193, 7, 1);
    border-radius: 5px;
    color: #fff;
    font-size: 1.3rem;
    text-transform: uppercase;
}
.typeLabelChat{
    background: rgb(7, 255, 210);
}
.labelTypeContainer{
    margin-top: 0.5rem;
    text-align: center;
}
.optionsAgentSelection{
    width:100%;
}