.optimization {
    display: grid;
    grid: auto auto minmax(0, 1fr) / 100%;
    gap: 1.5rem;
    /* grid-template-areas: "header header header header" "optionmenu optionmenu optionmenu optionmenu" "agentsContainer intents editIntent addIntent"; */
    grid-template-areas: "header" "typeSelection" "tab";
    transition: all 1s ease;
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.typeSelection{
    grid-area: typeSelection;
    height:100%;
    position: relative;
    display: flex;
    align-items: center;
}
.tab{
    grid-area: tab;
    height:100%;
    position: relative;
}

.tabPanel{
}

/* Dropdown Button */
.dropbtn {
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
    border-radius:0.5rem;
    padding:0.2rem 1rem;
    text-transform: uppercase;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: var(--buttonHoverBlue);
}

/* The search field */
#myInput {
    border: none;
    background: #eee;
    border-radius: 2rem;
    outline: none;
    width: 100%;
    color: gray;
    height: auto;
    margin: 0 !important;
    font-size: 1.1rem;
    padding: 1rem 1.3rem;
}
.dropdown {
    position: relative;
    display: inline-block;
    margin-left:auto;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    border: 1px solid #ddd;
    z-index: 1;
    flex-wrap: wrap;
    padding: 1rem;
}

/* Links inside the dropdown */
.dropdown-content span {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    cursor:pointer;
    width:100%;
    border-bottom:1px solid #eee;
}
.dropdown-content span:first-child{
    padding-top:0;
}
.dropdown-content span:last-child{
    padding: 12px 12px 0;
    border-bottom:0;
}
/* Change color of dropdown links on hover */
.dropdown-content span:hover {color: var(--buttonBlue)}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:flex;}

.dropdownIcon{
    margin-left:10px;
    transition:all 0.3s;
}
.rotate{
    transform:rotate(180deg);
}
.dropdownContainer{
    margin-left:auto;
    display:flex;
    gap:1rem;
}
.row{
    display:flex;
    width: fit-content;
    gap:0.5rem;
    width:50%;
}
.rowButton{
    width:300px;
}