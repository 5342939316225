.callback {
    display: grid;
    grid: auto minmax(0, 1fr) / auto;
    gap: 1.5rem;
    grid-template-areas: "header" "grid";
    transition: all 1s ease;
    height: 100%;
}

.widget {
    background: #fff;
    border-radius: 10px;
    padding: 0 1rem;
    -webkit-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);
    box-shadow: 0px 0px 8px 3px rgba(230, 230, 230, 1);

}

.header {
    grid-area: header;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.grid {
    grid-area: grid;
    overflow-y: scroll;
    overflow-x:hidden;
    flex-direction: column;
    gap: 1rem;
    /*-ms-overflow-style: none; !* IE and Edge *!*/
    /*scrollbar-width: none;*/
}
.optionMenu{
    padding:1rem;
}
.closeIcon{
    font-size:2rem;
    cursor:pointer;
}