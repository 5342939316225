.jsoneditor-react-container {
    width: 100%;
    height: 100%;
  }
  .jsoneditor-statusbar{
    display:none;
  }
  .jsoneditor-menu{
    display:none;
  }
  .jsoneditor-outer{
    margin-top: 0 !important; 
    padding-top: 0 !important;
  }
  .jsoneditor{
    border:1px solid #ddd;
    height:85%;
  }