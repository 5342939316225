.cardTitle{
    display: flex;
    justify-content: center;
}
.brainLogo{
    background:url('/public/brain_logo.png');
    height: 140px;
    width: 180px;
    overflow:hidden;
    background-size:180px;
    background-repeat: no-repeat;
}
.dgtlassistLogo{
    background:url('/public/dgtlassist_logo_blue.png');
    height: 115px;
    width: 170px;
    overflow:hidden;
    background-size:170px;
    background-repeat: no-repeat;
    position:absolute;
    right:15px;
    top:-55px;
}