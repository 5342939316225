.mainContainer{
    display:flex;
    height:100vh;
    /*background:#312E3F;*/
    background:#f8f8fa;
}
.content{
    flex: 22;
    display:grid;
    /* grid: 6rem 1fr / 100%; */
    grid-template-rows: 6vh 92vh;
    grid-template-columns: 100%;
    grid-template-areas:
            "header"
            "main";
    margin-top:0;
    height:100%;
    position:relative;
}
.header{
    grid-area:header;
    border-bottom:1.5px solid #eee;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 3rem;
}
.logo{
    margin-right: auto;
    margin-left: 3.5rem;
    background-image: url('/public/powered_by.png');
    background-size: 200px;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    background-repeat: no-repeat;
}

.icon{
    color:gray;
    font-size:1.8rem;
    padding:1.5rem;
    display:flex;
    cursor:pointer;
}
.icon:hover{
    color:var(--buttonBlue);
}
.icon:hover .notification{
    transform: scale(1.1);
}
.hasNotification{

}
.hasNotification .notification{
    position: relative;
    background: red;
    font-size: 0.9rem;
    right: -28px;
    top: -5px;
    border-radius: 50%;
    width: 1.5rem;
    color: white;
    height: 1.5rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blink 1s steps(5, start) infinite;
    -webkit-animation: blink 1s steps(5, start) infinite;
}
.user{
    border-left:1.5px solid #eee;
    padding-left:3rem;
    height:100%;
    align-items: center;
    display:flex;
    cursor: pointer;
}
.profile{
    background: #fbe2f7;
    color: #b6239c;
    font-weight: 600;
    width: 30px;
    border-radius: 50%;
    font-size: 1rem;
    justify-content: center;
    height: 30px;
    display: flex;
    align-items: center;
}
.account{
    margin-left:1rem;
}
.name{
    font-weight:500;
    font-size:1rem;
}
.email{
    font-size:0.8rem;
    font-weight:500;
    color:gray;
}
.main{
    grid-area:main;
    margin:2rem 3rem;
}
@keyframes blink {
    50% {
        opacity: 0.5;
        width: 1.5rem;
        height: 1.5rem;
    }
}

.brainLogo{
    display: flex;
    font-size: 1.7rem;
    margin-left: 3rem;
    font-weight: 400;
    color:var(--buttonBlue);
}
.brainLogo1{

}
.brainLogo2{
    color:var(--buttonBlue);
}
.brainPoweredBy, .brainDgtlAssist{
    font-size: 1rem;
    margin-left: 1rem;
    margin-top: 0.7rem;
    color:#444;
    font-weight:300;
}
.formPanel{
    padding:1rem;
    padding-top:0;
    padding-left:0;
}
.formRow{
    display:flex;
    gap:1rem;
    justify-content: space-between;
}
.formElementContainer{
    flex: auto;
}
.userOptions{
    display:none;
    position: absolute;
    top: 0;
    right: 0;
    top: 6%;
    width: 240px;
    z-index: 10;
    border: 1px solid #eee;
    border-top: none;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    background: white;
}
.userOptionsShow{
    display:block;
}
.updateButton{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-right: -8px;
    margin-left: 8px;
}
.disable{
    cursor:default;
    background:#eee;
}
.passwordRequirements{
    color: red;
    font-size: 1rem;
    margin-left: 1rem;
    margin-bottom: 5px;
}
.profileSettings{
    margin-left:1.5rem;
    cursor:pointer;
    font-size:1.5rem;
    color:gray;
}
.profileSettings:hover{
    color:#b6239c;
}
.updatePasswordWindow{
    padding: 1rem;
    cursor: pointer;
}
.updatePasswordWindow:hover{
    color:#b6239c;
}
.updatePasswordWindow:last-child{
    border-top: 1px solid #eee;
}
.updatePasswordPopup{
    margin-left:-1rem;
    margin-right:1rem;
    margin-top:1rem;
}


.updatePasswordDialog{
    display:grid;
    grid: 250px / minmax(250px, 1fr) minmax(250px, 1fr);
    grid-template-areas:"requirements fields";
    gap:1rem;
}
.requirementsContainer{
    grid-area: requirements;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.requirementsContainerTitle{
    font-size:2.3rem;
    color:#444;
    font-weight: 500;
}
.requirementsContainerSubTitle{
    font-weight: 300;
    color:#444;
    margin-bottom:1rem;
}
.requirementsContainerContent{
    
}
.fieldsContainer{
    grid-area: fields;
    display:flex;
    flex-direction: column;
    justify-content:center;

}
.requirementRowHeader{
    color:#bbb;
    font-weight: 300;
    margin-bottom:1rem;
    margin-top:1.5rem;
}
.requirementRow{
    font-weight: 300;
    margin-bottom:3px;
}
.valid{
    color:#28a745;
}
.invalid{
    color:#ddd;
}
.updateButtonPopup{
    margin-top:5px;
}
.qrInstructions{    
    display: flex;
    width: 100%;
    justify-content: center;
}
.closeDialog{
    position: absolute;
    top: 0;
    right: 10px;
    cursor:pointer;
}
.twoFactorUpdated{
    color:#b6239c;
}
.twoFactorUpdated{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}