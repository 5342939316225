.widgetHeader{
    display: grid;
    grid-auto-flow: column;
    justify-content: end;
    border-bottom: 1.5px solid #eee;
    padding-bottom:1rem;
    gap:1rem;
    position: relative;
}
.headerTitle{
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(0, 41, 103);
    margin-right:auto;
    left:0;
    position:absolute;
}
.buttonStyle{
    cursor: pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}
.submitButton{
}
.buttonStyle:hover, .buttonStyle:focus {
    background-color: var(--buttonHoverBlue);
}
.formPanel{
    padding:1rem;
    padding-left:0;
}
.formRow{
    display:flex;
    gap:1rem;
    justify-content: space-between;
}
.formColumn{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.formHeaderText{
    padding: 0.5rem;
    border-bottom: 1.5px solid #eee;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.dropdownContainer{
    display: inline-grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}
.dropdownHeader{
    display: flex;
    justify-content: space-between;
}
.formElementContainer{
    flex: auto;
}
.wFull{
    width:100px;
    flex-grow:5;
}
.w100{
    width:100px;
    flex-grow:0;
}
.disabledButton{
    background:#eee;
    cursor:default;
}
.disabledButton:hover{
    background:#eee;
}
.buttons{
    display:flex;
    right: 1rem;
    bottom: 1rem;
    position: absolute;
    gap:1rem;
}
.deleteButton{
    background:rgb(211, 47, 47);
    cursor:pointer;
}
.deleteButton:hover, .deleteButton:focus {
    background-color: rgb(180, 43, 43);
}
.formRowLeft{
    justify-content: left;
}