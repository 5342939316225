

.intent {
    display: grid;
    /* grid: 1fr auto / 6fr 1fr 2fr 1fr; */
    grid: 1fr / 5fr 1fr 2fr;
    border-bottom: 1.5px solid #eee;
    border-radius: 5px;
    overflow:hidden;
    /* padding: 0.5rem 0; */
    grid-template-areas: "intentInfo state popularity" "intentResponses intentResponses intentResponses";
    /* grid-template-areas: "intentInfo state popularity edit" "intentResponses intentResponses intentResponses intentResponses"; */
}
.intentActive{
    background:rgb(243, 247, 251);
}
.intent:hover{
    background:rgb(243, 247, 251);
}
.intentInfo {
    grid-area: intentInfo;
    padding: 0.2rem 2rem;
    cursor:pointer;
}
.state{
    grid-area: state;
    padding: 0 2rem;
    cursor:pointer;
    justify-content:center;
    display:flex;
    align-items:center;
}
.stateIcon{
    width:20px;
    height:20px;
    background:rgb(255, 193, 7);
    border-radius:3px;
    color:#fff;
    font-size:2rem;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0.5rem;
    position:relative;
}
.stateIcon:hover:after{
    content: attr(data-state);
    position: absolute;
    background:#444;
    padding:0.5rem 1rem;
    border-radius:5px;
    left:25px;
    font-size:1.5rem;
    z-index:1;
}
.ready{
    background:#28a745;
}
.inProgress{

}
.active{
    background:#28a745;
}
.inActive{
    background:#e05260;
}
.popularity{
    grid-area: popularity;
    padding: 0 2rem;
    cursor:pointer;

    justify-content:center;
    display:flex;
    align-items:center;
}
.popularity .line{
    width:100%;
    height:4px;
    border-radius:2px;
    background:rgb(232, 240, 248);
    overflow:hidden;
    position:relative;
}

.popularity .innerLine{
    height:4px;
    border-radius:2px;
    background:rgb(146 180 227);
}
.edit{
    justify-content:center;
    display:flex;
    align-items:center;
    grid-column-end: -1;
}
.editMargin{
    margin-right: 15px;
}
.edit span{
    grid-area: edit;
    cursor:pointer;
    background-color: var(--buttonBlue);
    color: white;
    font-size: 1.3rem;
    border: none;
    padding: 0.5rem 2rem;
    border-radius:0.5rem;
    justify-content:center;
    display:flex;
    align-items:center;
}
.intentName:hover::after{
    content: attr(data-tooltip);
    color:#fff;
    background:rgb(40, 40, 40);
    padding:0.5rem 1.4rem;
    border-radius:5px;
    font-size:1.2rem;
    margin-left:10px;
    margin-top:-3px;
    display:none;
}
.disable .intentName:hover::after{
    display:none;
}
.highlight .intentName:hover::after{
    display:none;
}
.intentName {
    font-size: 1.8rem;
    font-weight: 400;
    color:rgb(0, 41, 103);
    width:fit-content;
    gap: 1rem;
    display: flex;
}
.intentQuestion {
    grid-area: intentQuestion;
    font-size: 1.3rem;
    font-weight: 300;
    font-style: italic;
    color:rgb(40, 40, 40);
}

.saveIcon {
    font-size: 2rem;
    color: var(--buttonBlue);
    cursor: pointer;
}
.titleId{
    font-size: 0.9rem;
    color: #aaa;
    margin-top: -4px;
    font-weight: 100;
}
.intentResponses {
    grid-area: intentResponses;
    display: flex;
    flex-direction:column;
    overflow:hidden;
    margin: 0px 40px 0px 40px;
    gap:0.5rem;

    max-height:0;
    transition:max-height 0.3s;
}
.intentResponsesExpanded{
    height:fit-content;
    max-height:600px;
    margin: 5px 40px 5px 40px;
}
.highlight{
    transform: scale(1.03);
    background:rgb(243, 247, 251);
    border-bottom:none;
}
.disable{
    filter: blur(1.5px);
    opacity:0.6;
}

.disable:hover{
    background:#fff;
}
.disable .intentInfo, .disable .state, .disable .edit span{
    cursor:default;
}
.highlight .intentInfo, .highlight .state, .highlight .edit span{
    cursor:default;
}
.warningIcon{
    font-size: 1.5rem;
    margin-top: 2px;
}